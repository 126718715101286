.App {
    text-align: center;
  }
  
  .App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding: 1rem;
  }
  
  .App-link {
    color: #000;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  body {
    text-align: center;
    font-family: 'Inter';
  }
  .dropin-parent {
    max-width: 420px;
    margin: auto;
    margin-top: 20px;
  }
  
  .order-token {
    font-weight: 600;
  }
  
  .inputText {
    width: 200px;
  }
  .btn-render {
    border-radius: 6px;
    background-color: rgb(105, 51, 211);
    color: rgb(255, 255, 255);
    border: none;
    font-size: 14px;
    padding: 11px 16px;
  }
  input {
    padding: 0.67857143em 1em;
    border-radius: 6px;
    border: 1px solid #979797;
  }
  input:focus {
    outline-color: rgb(105, 51, 211);
  }
  
  .style-dropin {
    outline: 0;
    border-width: 0 0 1px;
    border-radius: 0px;
  }
  
  .style-dropin:focus {
    border-color: rgb(105, 51, 211);
  }
  
  .style-container {
    margin-bottom: 8px;
  }
  
  .mr-8 {
    margin-right: 0.5rem;
  }
  
  .mr-1 {
    margin-right: 1rem;
  }
  
  .mt-2 {
    margin-top: 2rem;
  }
  