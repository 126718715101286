.App {
    width: 50%;
    text-align: center;
}

.Timg {
    width: 100px !important;
    height: 100px !important;
    border-radius: 50%;
    background-color: #FFF;
    padding: 1%;
}

.myCarousel {
    margin-top: -8%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 2%;
    text-align: left;
    border-radius: 10px;
    display: grid;
    background-image: linear-gradient(to bottom right, #4bb080, #a3d7be);
}

.myCarousel1 {
    margin-top: -8%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 2%;
    text-align: left;
    border-radius: 10px;
    display: grid;
    background-image: linear-gradient(to bottom right, #d9d9d9, #d9d9d9);
}

@media only screen and (max-width: 934px) {
    .App {
        width: 100%;
        text-align: center;
    }
    .Timg {
        width: 22% !important;
        border-radius: 50%;
    }

    .myCarousel {
        margin-top: -8%;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        padding: 2%;
        text-align: left;
        border-radius: 10px;
        display: grid;
        background-image: linear-gradient(to bottom right, #4bb080, #a3d7be);
    }

    .myCarousel1 {
        margin-top: -8%;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        padding: 2%;
        text-align: left;
        border-radius: 10px;
        display: grid;
        background-image: linear-gradient(to bottom right, #d9d9d9, #d9d9d9);
    }
}